import styled, { css } from 'styled-components';
import { Dropdown, DropdownButton, DropdownItem, Modal, ProgressBar } from 'react-bootstrap';
const RegularFont = 'Rubik-Regular';

const Layout = styled.div`
  padding: 25px;
  width: 100%;
  font-family: ${RegularFont};
  margin-bottom: ${({ marginBottom }) => marginBottom ? marginBottom : '100px'};
  background-color: ${({ backgroundColor }) => backgroundColor ? backgroundColor : 'auto'}
  display: flex;
  @media (max-width: 767px) {
    padding: 30px 15px 20px;
  }
  `;

const Main = styled.div`
  width: 100%;
  max-width: 1248px; // 1246px;
  margin: auto;
`;

const Contaniner = styled.div`
width: 100%;
padding: 25px;

.main{
  width: 100%;
  max-width: 1246px;
  margin: auto;
}
`;
const ActivityDropdown = styled(DropdownButton)`
  // padding: ${({paddingValue}) => paddingValue? paddingValue : '0px 20px'};
  width: ${({widthValue}) =>widthValue? widthValue : '100%'};
  text-align: left;
  margin-top: 0px;
  margin: ${({ margin }) => margin};
  text-transform: capitalize;
  height: 50px;
  color: ${({notActive,newColor}) => notActive && newColor ? "#649bb3" : !notActive && newColor ? '#005c87' : notActive ? "#9c9c9c" : '#0D4270'};
  font-size:16px;
  font-family: ${({notActive}) =>notActive? 'rubik' : 'rubik-medium'};
  border: 1px solid #cdcbcb;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  background:rgba(156,156,156,.04);

  button{
    width: 100%;
    height: 100%;
    background: rgb(250, 249, 244);
    border: 1px solid rgb(175, 203, 211);
    color: rgb(0, 92, 135);
    border-color: #adadad;
    text-align: left;

    &:hover{
      background: rgb(250, 249, 244);
      border: 1px solid rgb(175, 203, 211);
      color: rgb(0, 92, 135);
    }

    ::after{
      display: none;
    }
  }

  .dropdown-menu.show{
    a{
      text-transform: capitalize;
      width: 100% !important;
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: 400;
      line-height: 1.42857143;
      color: #333;
      white-space: nowrap;

      &:hover{
        outline: none;
        color: rgb(0, 92, 135) !important;
        background-color: rgba(105, 194, 255, 0.1) !important;
        border-radius: 6px !important;
      }
      
    }
    .dropdown-item.active{
      background: rgb(13, 66, 112);
      color: #fff;
      text-decoration: none;
      outline: 0;
    }
  }

  >span{
    display:none;
  }
  &:active,  &:focus, &:active:focus {
    outline: none;
    background-color: white;
    box-shadow: none;
    // color: #9c9c9c;
    color: #005c87 !important;
    font-size:16px;
    font-family: 'rubik-medium';
    border: 1px solid #9C9C9C;
  }
`;

// New dropdown for On-demand Event creation - Class Category
const ClassCategoryDropdown = styled(Dropdown)`
  width: ${({widthValue}) =>widthValue? widthValue : '100%'};
  text-align: left;
  margin-top: 0px;
  margin: ${({ margin }) => margin};
  text-transform: capitalize;
  height: ${({height}) =>height? height : '60px'};
  font-size:16px;
  font-family: ${({notActive}) =>notActive? 'rubik' : 'rubik-regular'};
  border: 1px solid #005C874D;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  background:rgba(156,156,156,.04);
  border-radius: 6px;
  button{
    width: 100%;
    height: 100%;
    background: none;
    color:  ${({notActive}) =>notActive? '#005C8799' : '#005C87'};
    font-family: ${({notActive}) =>notActive? 'rubik' : 'rubik-medium'};
    border: none;
    text-align: left;
    &:hover{
      > img{
        border: 1px solid black;
      }
    }
    &:hover, &:active, &:active:focus{
      background: none;
      color: rgba(0, 92, 135, 0.6);
    }
        
    &:active, &:active:focus{
      color: #333;
      -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
      box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
      background: rgb(250, 249, 244);
      border: 1px solid rgb(175, 203, 211);
    }
    &:focus{
      background: none !important;
      color: #333;
    }
    &::after{
      display: none;
    }
  }
  .dropdown-menu{
    width: 70%;
    flex-direction: column;
    .dropdown-item{
      padding: 3px 0px 3px 15px;
      background: none;
      &:hover{
        color: rgb(0, 92, 135);
        font-family: Rubik-medium;
        background: rgba(0, 92, 135, 0.05);
      }
      &:active{
        color: rgba(0, 92, 135, 0.6);
        font-family: Rubik-medium;
        background: rgb(13, 66, 112);
      }
    }
    .dropdown-item > img{
      width: 16px;
      height: 16px;
      float: right;
      margin: auto 15px auto auto;
      display: none;
    }
    .dropdown-item:hover > img{
      display: flex;
    }
    .dropdown-item:active > img{
      display: flex;
    }
  }
  >span{
    display:none;
  }
  &:active,  &:focus, &:active:focus {
    outline: none;
    background-color: white;
    box-shadow: none;
    // color: #9c9c9c;
    color: #005C87;
    font-size:16px;
    font-family: 'rubik-medium';
    border: 1px solid #005C874D;
  }
  &:hover{
    background:rgba(156,156,156,.04);
    color: #005C87;
    border:1px solid #005C874D;
  }
  .btn-default.active, .btn-default:active, .open>.dropdown-toggle.btn-default{
    border: 1px solid #005C874D;
  }
  .btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover, .open>.dropdown-toggle.btn-default.focus, .open>.dropdown-toggle.btn-default:focus, .open>.dropdown-toggle.btn-default:hover{
    color:#005C87;
  }
`;

const CustomMenuItem = styled(DropdownItem)`
  width: 100% !important;
  text-transform: capitalize;
  > a {
    &:active, &:hover, &:focus {
      outline: none;
      color: #005c87 !important;;
      background-color:rgba(105,194,255,0.1)!important;
      border-radius:6px !important;
    }
  }
`;

const DropdownArrow = styled.img`
float: right;
top:${({ drop, top }) =>top?top: drop ? "67px" : " 65px"};
position: absolute;
right: 15px;
right:${({ right }) =>right?right : "15px"};
display:block;
z-index:5;
cursor: pointer;
`;

const TitleContainer = styled.div`
  float: left;
  width: ${({ width }) => width ? width : '100%'};
  padding: ${({ padding }) => padding ? padding : '15px 0px 10px 0px'};
  margin: ${({ margin }) => margin};
  position: relative;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  .dropdown{
    border: ${({isEditOnDemand}) => isEditOnDemand && '0px solid black !important'} ;
  }
  > div {
    float: left;
    width: 100%;
    position: relative;
  }
  .main{
    display: inline-block;
    width: 100%;
    >span{
      font-size: 12px;
    }
  }
  .outerDiv{
    display: flex;
    >div:first-child{
      width: 70%;
    }
    >div:last-child{
      width: 30%;
      display: flex;
      .padding{
        padding-top: 10px;
      }
      >div:first-child{
        height: 100%;
        width: 20%;
        >label{
          margin: 0;
        }
      }
      >div:last-child{
        width: 80%;
      }
    }
  }

  ${({ addMargin }) => addMargin && css`
    > div {
      margin-top: 10px;
    }
  `}

  @media (max-width: 550px) {
    padding: 30px 15px 30px;
  }

  svg {
    position: absolute;
    top: 15px;
    right: 0;
  }

  .dropdown-menu {
    display: ${({ display }) => display ? display : ""};
    width:${({ fullWidth }) => fullWidth ? "100%" : "50%"};
    overflow-y: auto;
    position:relative;
 >.active>a:hover {color:#005c87}
  }

  .dropdown-menu>.active>a{
    background:#0D4270;
  }
  @media (max-width: 1000px){
    width: 100%;
  }
`;

const CustomDropdown = styled(DropdownButton)`
  padding: 0px 15px;
  width: 100%;
  text-align: left;
  margin-top: 0px;
  text-transform: capitalize;
  height: 50px;
  color: #0D4270;
  font-size:16px;
  font-family: 'rubik-medium';
  border: 1px solid #9C9C9C;
  .caret{
    color: #0D4270;
    float: right;
    margin-top:4px;
    // margin-left :13px;
    border-top: 10px dashed;
    border-top: 4px solid\9;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
  }
 .title{
  font-family: Rubik;
  float: left;
  font-size: 16px;
  line-height: 20px;
  color: rgb(156, 156, 156);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 140px;
  border: 10px solid black;
 }
  &:active,  &:focus, &:active:focus {
    outline: none;
    background-color: white;
    box-shadow: none;
    // color: #9c9c9c;
    color: #0D4270;
    font-size:16px;
    font-family: 'rubik-medium';
    border: 1px solid #9C9C9C;
  }
  & .btn:hover{
    color:#005c87 !important;
  };
`;

const NewCustomDropdown = styled(DropdownButton)`
  button{
    padding: 0px 15px;
    text-align: left;
    margin-top: 0px;
    text-transform: capitalize;
    height: 50px;
    color: rgb(13, 66, 112);
    font-size: 16px;
    font-family: rubik-medium;
    border: 1px solid rgb(156, 156, 156);
    background-color: #fff;
    float: left;
    width: 200px;
    &:hover{
      border: 1px solid rgb(156, 156, 156);
      background-color: #fff;
      color: rgb(13, 66, 112);
    }
    &:active:focus{
      color: #333;
      background-color: #d4d4d4;
      border-color: #8c8c8c;
      -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
      box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    }
    &:focus{
      color: #333;
      background-color: #d4d4d4;
      border-color: #8c8c8c;
      -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
      box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    }
    &::after{
      color: rgb(13, 66, 112);
      float: right;
      margin-top: -15px;
      border-top: 10px dashed;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
    }
  }
`;

const CustomMenuItemEmployee = styled(DropdownItem)`
  width: 100% !important;
  text-transform: capitalize;
  // padding:15px;
  >span{
    display:block;
  }
  > a {
    &:active, &:hover, &:focus {
      outline: none;
      color:#005c87;
      background-color:rgba(105,194,255,0.1)!important;
      border-radius:6px !important;
    }
  }
`;

const DropdownArrowEmployee = styled.img`
float: right;
top:${({ drop }) => drop ? "33px" : " 30px"};
position: absolute;
right: 12px;
display:block;
z-index:2;
onClick={() => this.setState({ pointsDrop: !pointsDrop })};
onClick={() => this.setState({ pointsDrop: !pointsDrop })};
`;

const FieldTitle = styled.span`
  font-size: 16px;
  margin-bottom : 15px;
  letter-spacing: 0px;
  line-height: 16.5px
  color: #0D4270;
  font-family: 'Rubik';
  float: left;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  width: ${({ fullWidth }) => fullWidth && fullWidth};
  .day {
    font-size: 18px;
    color: #159fc9;
  }
  >span{
    color:#F6797D;
    font-size: 18px;
  }
`;

const TitleContainerEmployee = styled.div`
  float: left;
  padding: 15px 0px 10px 0px;
  margin-right:15px;
  position: relative;
 .searchDiv{
  display:flex;
  border-radius:4px;
  flex-direction:row;
  position: relative;
  border: 1px solid #9C9C9C;
 }
  > div {
    float: left;
    width: 100%;
    position: relative;
  }
  .main{
    display: inline-block;
    width: 100%;
    >span{
      font-size: 12px;
    }
  }
  .outerDiv{
    display: flex;
    >div:first-child{
      width: 70%;
    }
    >div:last-child{
      width: 30%;
      display: flex;
      .padding{
        padding-top: 10px;
      }
      >div:first-child{
        height: 100%;
        width: 20%;
        >label{
          margin: 0;
        }
      }
      >div:last-child{
        width: 80%;
      }
    }
  }

  ${({ addMargin }) => addMargin && css`
    > div {
      margin-top: 10px;
    }
  `}

  @media (max-width: 550px) {
    padding: 30px 15px 30px;
  }

  svg {
    position: absolute;
    top: 15px;
    right: 0;
  }

  .dropdown-menu {
    width:${({ fullWidth }) => fullWidth ? "100%" : "50%"};
    width:auto;
 >.active>a:hover {color:black}
  }
  @media (max-width: 1000px){
    width: 100%;
  }
`;

const OnDemandButton = styled.div`
width: 385px;
background: #FD7175;
height: 50px;
display: flex;
justify-content: center;
border-radius: 6px;
cursor: pointer;
${({ disabled }) => disabled === true && css`
opacity: 0.6;
background:#9c9c9c;
cursor: not-allowed !important;
`}
>span{
  font-size: 18px;
  font-family: 'Rubik-Medium';
  line-height: 18px;
text-align: center;
color: white;
margin: auto;
}
`;

const TeamDetailsStyledModal = styled(Modal)`
  .modal-dialog {
    width:500px;
    .modal-content{
      border-radius: 7px 7px 6px 6px;
    }
    .modal-header {
      ${'' /* background: linear-gradient(180deg,#52A8ED 0%,#8AD3F7 100%); */}
      background: #005C87;
      border-radius: 6px 6px 0px 0px;
    }
    .modal-footer{
      height:80px;
      display:flex;
      justify-content:center;
      align-items:center;
    }
    .modal-body{
      padding: 0px;
      width:100%;
      // height:400px;
      display:flex;
      justify-content:center;
      align-items:center;
      flex-direction:column;
      .teamWrapper{
        width:100%;
        display:block;
        height: 500px;
        padding: 25px;
        overflow-x: hidden;
        overflow-y: auto;
        ::-webkit-scrollbar {
          width: 7px;
          height: 45px;
        }
        ::-webkit-scrollbar-thumb {
          background: #c6c2c2;
          cursor: pointer;
          border-radius: 2.5px;
        }
      
        ::-webkit-scrollbar-track {
          background: #f2f0f0;
          border-radius: 2.5px;
        }
      }
      .teamNameDiv{
         width:100%;
        display:flex;
        justify-content:flex-start;
        align-items:center;
         flex-direction:column;
         .lable{
            ${'' /* width:100%; */}
            display:flex;
            margin-bottom:15px;
            justify-content:flex-start;
            align-items:center;
            font-family: 'Rubik';
               font-style: normal;
             font-weight: 400;
              font-size: 16px;
             line-height: 20px; 
             color: #0D4270;
               >span{color:red;}
            }
            >lable{
            width:100%;
            display:flex;
            margin-bottom:15px;
            justify-content:flex-start;
            align-items:center;
            font-family: 'Rubik';
               font-style: normal;
             font-weight: 400;
              font-size: 16px;
             line-height: 20px; 
             color: #0D4270;
               >span{color:red;}
            }
            >input{
              background: rgba(156, 156, 156, 0.1);
              border: 1px solid rgba(156, 156, 156, 0.4);
              border-radius:3px;
              font-family: 'Rubik-medium';
              padding:13px 15px;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #0D4270;
              width:100%;
              height:50px;
             }
           }

        .teamLogoDiv{
          margin:25px 0;
          width:100%;
          display:flex;
          justify-content:flex-start;
          align-items:center;
           flex-direction:column;
           >h4{
            width:100%;text-align:left;
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #0D4270;
           }
           .radioButtonDiv{
            width:100%;
            display:flex;
            justify-content:flex-start;
            align-items:center;flex-direction:row;
            span{  width:100%;
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height, or 125% */
            display: flex;
            align-items: center;
            /* navy */
            color: #0D4270;
              display:flex;
              justify-content:flex-start;
              align-items:center;
              flex-direction:row;}
           }

          }  
          .selectedDiv{
            display:flex;
            justify-content:flex-start;
            margin-top:17px;
            flex-wrap:wrap;
            width:450px;     
          }
.logoDivInner{ display:flex;
  justify-content:flex-start;
  margin-top:17px;
  width:100%;
  height:150px;
  div{
    >img{
    width:150px;
    height:150px;border-radius:6px;
  }

  }
  >img{
    width:150px;
    height:150px;border-radius:6px;
  }
  >button{margin-left:20px;
    background:${({ removeButton }) => removeButton ? "#FD7175" : "#69c2ff"};
    border:none;
    color:white;font-size:18px;
    font-family:rubik-medium;

    width:120px;
    height:50px;border-radius:4px;
    display:flex;
    justify-content:center;
    align-items:center;
   }
 }
}
    @media (max-width: 1000px) {
   width: 60%;
    }
    
    @media (max-width: 767px) {
      width: 90%;
      margin: 30px auto;
    }
  }
`;

const SeeAllStyledModal = styled(Modal)`
  .modal-dialog {

  .btns{
    display:flex;
    justify-content:space-between;
    font-family: rubik-medium;
    .btn1{
      background:#FD7175;
      color:white;
      border:none;
      width:125px;
      text-decoration:none;
      border-radius:4px;
      height:50px;
    }

    .btn2{
      background:white;
      color:#FD7175;
      border:1px solid #FD7175;
      width:125px;
      border-radius:4px;
      height:50px;
      margin-left:20px;
    }
  }
    width:500px;
    // height:400px;
    .modal-content{
      border-radius: 7px 7px 6px 6px;
    }
    .modal-header {
      background: #005C87;
      border-radius: 6px 6px 0px 0px;
    }
    .modal-footer{
      height:80px;
      display:flex;
      justify-content:center;
      align-items:center;
      
    }
    .modal-body{
      padding: 0px;
      width:100%;
      // height:400px;
      display:flex;
      justify-content:center;
      align-items:center;
      flex-direction:column;
      // height: 550px;
      // overflow-x: hidden;
      // overflow-y: auto;
      // ::-webkit-scrollbar {
      //   width: 7px;
      //   height: 45px;
      // }
    
      // ::-webkit-scrollbar-thumb {
      //   background: #c6c2c2;
      //   cursor: pointer;
      //   border-radius: 2.5px;
      // }
    
      // ::-webkit-scrollbar-track {
      //   background: #f2f0f0;
      //   border-radius: 2.5px;
      // }
      .teamNameDiv{
         width:100%;
        display:flex;
        justify-content:flex-start;
        align-items:center;
         flex-direction:column;
            >lable{
            width:100%;
            display:flex;
            margin-bottom:15px;
            justify-content:flex-start;
            align-items:center;
            font-family: 'Rubik';
               font-style: normal;
             font-weight: 400;
              font-size: 16px;
             line-height: 20px; 
             color: #0D4270;
               >span{color:red;}
            }
            >input{
              background: rgba(156, 156, 156, 0.1);
              border: 1px solid rgba(156, 156, 156, 0.4);
              border-radius:3px;
              font-family: 'Rubik-medium';
              padding:13px 15px;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #0D4270;
              width:100%;
              height:50px;
             }
           }

        .teamLogoDiv{
          margin:25px 0;
          width:100%;
          display:flex;
          justify-content:flex-start;
          align-items:center;
           flex-direction:column;
           >h4{
            width:100%;text-align:left;
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #0D4270;
           }
           .radioButtonDiv{
            width:100%;
            display:flex;
            justify-content:flex-start;
            align-items:center;flex-direction:row;
            span{  width:100%;
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height, or 125% */
            display: flex;
            align-items: center;
            /* navy */
            color: #0D4270;
              display:flex;
              justify-content:flex-start;
              align-items:center;
              flex-direction:row;}
           }

          }  
          .selectedDiv{
            display:flex;
            justify-content:flex-start;
            margin-top:17px;
            flex-wrap:wrap;
            width:450px;
           
           
          }
.logoDivInner{ display:flex;
  justify-content:flex-start;
  margin-top:17px;
  width:100%;
  height:150px;
  >img{
    width:150px;
    height:150px;border-radius:6px;

  }
  >button{margin-left:20px;
    background:${({ removeButton }) => removeButton ? "#FD7175" : "#69c2ff"};
    border:none;
    color:white;font-size:18px;
    font-family:rubik-medium;

    width:120px;
    height:50px;border-radius:4px;
    display:flex;
    justify-content:center;
    align-items:center;
   }
 }
}
    @media (max-width: 1000px) {
   width: 60%;
    }
    
    @media (max-width: 767px) {
      width: 90%;
      margin: 30px auto;
    }
  }
`;

const SelectImage = styled.div`
  position:relative;
  width:60px;
  height:60px;margin:0 15px 15px 0;
  border-radius:6px;
  >img{width:60px;
    border-radius:6px;
    background: #69c2ff;
    height:60px;}
    .tick{
      position:absolute;
      top:-7px;
      right:-5px;
      width:22px;
    height:22px;
    border-radius: 50%;
    }
              
`
const CustomRadioButton = styled.div`
margin-right:10px;
width:24px;
height:24px;
border:1px solid #9c9c9c;
border-radius:50%;
display: flex;
justify-content:center;
align-items:center;
>div{
  width:14px;
  height:14px;
  border-radius:50%;
  background:#fd7175
}
`
const AddEmployeeStyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 60%;
    .modal-content{
      border-radius: 7px 7px 6px 6px;
    }
    .modal-header {
      background: #005C87;
      border-radius: 6px 6px 0px 0px;
    }
    .modal-footer{
      height:80px;
      display:flex;
      justify-content:center;
      align-items:center;
      
    }
    .modal-body{
      padding: 5px 0 0 0;
      width:100%;
      display:flex;
      justify-content:center;
      align-items:center;
      flex-direction:column;
      .dropdownWrapper{

        width:100%;
        height:100px;
        display:flex;
        justify-content:flex-start;
        align-items:center;
        padding:25px;
        border-bottom:1px solid #9c9c9c;
        .text{
          font-family:rubik;
          font-size:16px;
          margin-right:15px !important;
        }
      }
>h2{
  margin:25px 0;
  font-family: 'Rubik';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
/ identical to box height, or 133% /

text-align: center;
text-decoration-line: underline;

color: #0D4270;
}
>h3{
margin:0 0 30px 0;
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
/ or 125% /

display: flex;
align-items: center;
text-align: center;

color: #0D4270;
}
>h4{
  margin:0 0 25px 0;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  / or 125% /
  
  display: flex;
  align-items: center;
  text-align: center;
  
  color: #0D4270;
  }
      >img{
        width:112px;
        
      }
    }
    @media (max-width: 1000px) {
 width: 60%;
    }
    
    @media (max-width: 767px) {
      width: 90%;
      margin: 30px auto;
    }
  }
`;

const StyledModal = styled(Modal)`
margin-top: 120px;
  .modal-dialog {
    width:900px;
    // height:400px;
    .modal-content{
      border-radius: 7px 7px 6px 6px;
    }
    .modal-header {
      background: linear-gradient(180deg,#52A8ED 0%,#8AD3F7 100%);
      border-radius: 6px 6px 0px 0px;
    }
    .modal-footer{
      height:100px;
      display:flex;
      justify-content:center;
      align-items:center;
      >div{
        display:flex;
        justify-content:center;
        align-items:center;
        cursor:pointer;
        background:#fd7175;
        border-radius:3px;
        width:350px;
        height:50px;
        font-family: 'Rubik-medium';
        
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        / identical to box height, or 133% /
        
        text-align: center;
        
        color: #FFFFFF;
        
      }
    }
    .modal-body{
      padding: 5px 0 0 0;
      width:400px;
      // height:400px;
      display:flex;
      justify-content:center;
      align-items:center;
      flex-direction:column;
>h2{
  margin:25px 0;
  font-family: 'Rubik';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
/ identical to box height, or 133% /

text-align: center;
text-decoration-line: underline;

color: #0D4270;
}
>h3{
margin:0 0 30px 0;
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
/ or 125% /

display: flex;
align-items: center;
text-align: center;

color: #0D4270;
}
>h4{
  margin:0 0 25px 0;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  / or 125% /
  
  display: flex;
  align-items: center;
  text-align: center;
  
  color: #0D4270;
  }
      >img{
        width:112px;
        
      }
    }

    @media (max-width: 1000px) {
      width: 60%;
    }
    
    @media (max-width: 767px) {
      width: 90%;
      margin: 30px auto;
    }
  }
`;

const StyledModalCreateTeams = styled(Modal)`
margin-top: 120px;
  .modal-dialog {
    width:400px;
    height:410px;
    .modal-content{
      border-radius: 7px 7px 6px 6px;
    }
    .modal-header {
      background: linear-gradient(180deg,#52A8ED 0%,#8AD3F7 100%);
      border-radius: 6px 6px 0px 0px;
    }
    .modal-footer{
      height:100px;
      display:flex;
      justify-content:center;
      align-items:center;
      >div{
        display:flex;
        justify-content:center;
        align-items:center;
        cursor:pointer;
        background:#fd7175;
        border-radius:3px;
        width:350px;
        height:50px;
        font-family: 'Rubik-medium';
        
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        / identical to box height, or 133% /
        
        text-align: center;
        
        color: #FFFFFF;
        
      }
    }
    .modal-body{
      padding: 5px 0 0 0;
      width:400px;
      // height:400px;
      display:flex;
      justify-content:center;
      align-items:center;
      flex-direction:column;
      .csvH2{
        background: linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        position:relative;
      }
      .csvH2::before {
        content: "";
        position: absolute;
        top: 100%;
        width: 100%;
        left: 0;
        height: 1px;
        border-radius: 2px;
        background:linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%);
      }
>h2{
  margin:25px 0 15px 0;
  font-family: 'Rubik-medium';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
/ identical to box height, or 133% /

text-align: center;
text-decoration-line: underline;

color: #0D4270;
}
>h3{
margin:0 0 30px 0;
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
/ or 125% /

display: flex;
align-items: center;
text-align: center;

color: #0D4270;
}
>h4{
  margin:0 0 25px 0;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  / or 125% /
  
  display: flex;
  align-items: center;
  text-align: center;
  
  color: #0D4270;
  }
      >img{
        width:112px;
        
      }
    }

    @media (max-width: 1000px) {
      width: 60%;
    }
    
    @media (max-width: 767px) {
      width: 90%;
      margin: 30px auto;
    }
  }
`;

const StyledCSVModal = styled(Modal)`
margin-top: 120px;
  .modal-dialog {
    width:600px;
     height:300px;
    .modal-content{
      border-radius: 7px 7px 6px 6px;
    }
    .modal-header {
      background: linear-gradient(180deg,#52A8ED 0%,#8AD3F7 100%);
      border-radius: 6px 6px 0px 0px;
      height:50px;
      color:white;font-family:rubik-medium;
      font-size:18px;
  
    }
   
.modal-body{
      padding: 25px;
      width:100%;
      // height:400px;
      display:flex;
      justify-content:flex-start;
      align-items:flex-start;
      flex-direction:column;
      .cardWrapper{
        width:100%;
        display:flex;
        justify-content: space-between;
        .text{
          font-family: Rubik;
          font-size: 16px;
          line-height: 20px;
          color:#0D4270;
        }
        .downloadText{
          font-family: Rubik;
          font-size: 16px;
          line-height: 20px;
          color:#9FC989;
          text-decoration: underline;
        }
      }
      .modalBody{
        width: 100%;
        display:flex;
        border: 1px dashed #69C2FF;
        border-radius:6px;
        margin-top:15px;
        height: 160px;
        flex-wrap:wrap;
        .deleteButton{
          width:100%;
          display:flex;
          justify-content:center;
          .button{
            height: 30px;
            border: 1px solid rgba(156, 156, 156, 1);
            border-radius: 6px;
            padding: 0px 13px;
            font-family: Rubik-Medium;
            font-size: 14px;
            line-height: 18px;
            color: #9c9c9c;
            cursor:pointer;
            text-align: center;
            align-items: center;
            display: flex;

          }
        }
        .uploadCsv{
          display: flex;
          width: 100%;
          justify-content: center;
          margin: auto;
          // cursor:pointer;
          margin-bottom: 0px;
          .imageWrapperUpload{
            width: 60px;
            height:60px;
            cursor:pointer;
            margin-right: 0px;
            >img{
              width: 100%;
              height:100%;
            }
          }
          .imageWrapperNotUpload{
            width: 65px;
            height:65px;
            cursor:pointer;
            margin-right: 0px;
            >img{
              width: 100%;
              height:100%;
            }
          }
          .imageWrapper{
            width: 36px;
            height:36px;
            cursor:pointer;
            margin-right: 0px;
            >input {
              opacity: 0;
              bottom: 0;
              width: 36px;
              height: 36px;
              margin: auto;
              margin-top: -30px;
              cursor:pointer;
            }
            >img{
              width: 100%;
              height:100%;
            }
          }
        }
        .selectText{
          font-family: Rubik-Medium;
          font-size: 16px;
          line-height: 20px;
          color: #69C2FF;
          justify-content: center;
          text-align: center;
          width: 100%;
          margin: auto;
          margin-top: 10px;
        }
        .uploadText{
          font-family: Rubik;
          font-size: 14px;
          line-height: 20px;
          color: #69C2FF;
          justify-content: center;
          text-align: center;
          width: 100%;
          margin: auto;
          margin-top: 10px;
        }
        .notUploadText{
          font-family: Rubik;
          font-size: 14px;
          line-height: 20px;
          color: #FD7175;
          justify-content: center;
          text-align: center;
          width: 100%;
          margin: auto;
          margin-top: 10px;
        }
      }
    >h2{
    margin:0;
    margin-bottom:25px;
    font-family: 'Rubik-medium';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    color: #0D4270;
    }
    .downloadbtndiv{
      width:100%;
      display:flex;
      justify-content:flex-start;
      align-items:center;
    }
    .downloadCsvBtn{
      text-decoration:none;
      cursor:pointer;
      width:285px;
      height:50px;
      display:flex;
      justify-content:center;
      align-items:center;
      border-radius:4px;
      background:#9FC989;
      margin-right: 10px;
}
      img{
        width:24px;
        height:24px;margin-right:12px;
      }
      h4{ margin:0;
        color:#fff;
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
      }
    }

    .uploadCsvDiv{
      width:100%;
      // height:400px;
      display:flex;
      justify-content:flex-start;
      align-items:flex-start;

    .uploadCsvBtn{
      text-decoration:none;
      position:relative;
      cursor:pointer;
      width:285px;
      height:50px;
      display:flex;
      justify-content:center;
      align-items:center;
      border-radius:4px;
      background:#69c2ff;
      margin-right:15px;
      > input {
        opacity: 0;
        position: absolute;
        bottom:0;
        width:100%;
        height:100%;
      }
      img{
        width:24px;
        height:24px;margin-right:12px;
      }
      h4{ margin:0;
        color:#fff;
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
      }
    }
    >h5{  color:#fd7175;
      font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    }
  }
}
.modal-footer{
  height:80px;
  display:flex;
  justify-content:center;
  align-items:center;
  padding: 25px;
  .sumbitButton{
    height: 50px;
    width: 100%;
    border-radius: 5px;
    cursor:pointer;
    background:${({ dissabledSubmit }) => dissabledSubmit ? "rgba(156,156,156,0.4)" : "#fd7175"};
    display:flex;
    text-align:center;
    font-family: Rubik-Medium;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color:white;

  }
  >div{
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    background:#fd7175;
    border-radius:3px;
    width:285px;
    height:50px;
    font-family: 'Rubik-medium';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    / identical to box height, or 133% /
    text-align: center;
    color: #FFFFFF;
  }
   >button{
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    background:${({ dissabledSubmit }) => dissabledSubmit ? "rgba(156,156,156,0.4)" : "#fd7175"};
    border-radius:3px;
    border:none;
    width:285px;
    height:50px;
    font-family: 'Rubik-medium';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    / identical to box height, or 133% /
    text-align: center;
    color: #FFFFFF; 
  }
}
    @media (max-width: 1000px) {
      width: 60%;
    }
    @media (max-width: 767px) {
      width: 90%;
      margin: 30px auto;
    }
  }
`;

const CustomTitle = styled(Modal.Title)`
float: left;
width: 100%;
> div {
  float: left;
  width: 100%;
  display: flex;
  > div {
    font-size: 12px;
    color: #a9a9a9;
    display: flex;
  }

  > span {
    font-size: 18px;
    letter-spacing: 0px;
    font-family: rubik-medium;
    color: white;   
    width: 70%;
  }
  .closeImage{
    width: 30%;
    display: flex;
    justify-content: flex-end;
    >img{
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}

@media (max-width: 500px) {
  padding: 0 10px;
}
`;

const StyledProgressBar = styled(ProgressBar)`
width: 330px;
margin-top:30px;
height:unset;
>div{
  height:10px;
  max-width:100%;
  background: linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%);
  border-radius: 14px;
transform: matrix(-1, 0, 0, 1, 0, 0);
}
`
const CustomInput = styled.input`
  font-size: 16px;
  padding: 12px 10px;
  border-radius: 4px;
  border: 1px solid #cdcbcb;
  float: right;
  width: 100%;
  padding-right: 25%;
  
  &:hover, &:active, &:focus {
    outline: none;
  }
`;

const StyledInputNew = styled(CustomInput)`
  float: left;
  margin-top: 0px;
  border-radius: 4px;
  background-color: ${({ disabled }) => disabled ? '#e6e6e6' : '#ffffff'};
  border: 1px solid #9C9C9C;
  height: 50px;
  font-size: ${({ locationInput }) => locationInput ? '14px' : '16px'};
  padding: 0 15px;
  color: #0D4270;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  width: 100%;
  font-family: 'rubik-medium';

  /* Chrome, Safari, Edge, Opera */
 ::-webkit-outer-spin-button,
 ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
 [type=number] {
    -moz-appearance: textfield;
  }

  &:focus, &:active {
    outline: none;
  }

  ::placeholder {
    color: #9c9c9c;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #9c9c9c;
  }

  ::-ms-input-placeholder {
    color: #9c9c9c;
  }
`;

const CardWrapper = styled.div`
  width: 100%;
  margin-top: ${({ margin }) => margin ? '25px' : ''};
  .image{
    display:flex;
    width:388px;
    height:288px;
    // background:#D9D9D9;
    border-radius:50%;
    margin:auto;
    margin-top:50px;
    >img{
      width:100%;
      width:100%;
    }
  }
  .teamText{
    width: 550px;
    color: var(--Navy_blue, #005C87);
    text-align: center;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin: auto;
    line-height: 20px; 
  }
  .container{
    display: flex;
    margin-top:25px;
    justify-content: center;
  }
  .teamWrapper{
    width:100%;
    display:flex;
    flex-wrap:wrap;
    // justify-content:space-between;
  }

 
`;

const TeamCard = styled.div`
  width: calc((100% - 50px) / 3);
  ${'' /* height: calc(100% - 50px); */}
  position:relative;
  display: block;
  border-radius:6px;
  margin-bottom:25px;
  background: white;
  margin-right:${({ margin }) => margin ? "0px" : "25px"};
  .educateImageBackground {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px 6px 0 0;
    .getStarted {
      font-family: 'Rubik-Medium';
      font-weight: 500;
      font-size: 18px;
      line-height: 92%;
      letter-spacing: 0px;
      min-width: 150px;
      height: 40px;
      background: linear-gradient(227.68deg, #00F0FF 15.32%, #3586FF 85.04%);
      color: white;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-align:center;
      padding:0px 15px;
    }
  }
  .header{
    width:100%;
    height:70px;
    display:flex;
    background: #005C87;
    border-radius:6px 6px 0 0;
    .teamLogo{
      height: 48px;
      background-color:#fff;
      border-radius:48px;
      width: 50px;
      padding:${({ logo }) => logo ? "0" : "8px"};
      margin: auto;
      margin-left: 15px;
      margin-right: 0px;
      display:flex;
      justify-content:center;
      align-items:center;
      ${'' /* border-radius: 4px; */}
      >img{
      height: 100%;
      width: 100%;
      border-radius: 48px;
      }
    }
  .teamName{
    font-family: Rubik;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    letter-spacing: 0px;
    display:flex;
    padding-left: 10px;
    margin: auto;
    margin-left: 0px;
    color: #ffff;
  }
  .menuIcon{
    margin: auto;
    margin-right: 15px;
    display:flex;
    width:25px;
    position:relative;
    div{
      button{
        border: none;
        box-shadow: none;
        background: transparent !important;
        margin-left: -30px;
        display: flex;
      }
      position:relative;
      >img{
      width:25px;
      cursor:pointer;
    }

    }
    >img{
      height:6px;
      width:25px;
      cursor:pointer;
    }
    .btn-group{
position:absolute;
top:-3px;
>button{
  border:none;
  background-color:transparent !important;   
background:transparent !important;
box-shadow:none;

}
>ul{min-width:unset;
  width:135px;

  left:-105px;}

    }
  }
}
.teamDetails{
  display: block;
  width:100%;
  height: calc(100% - 70px);
  flex-direction:column;
  justify-content:center;
  align-items:center;
  background:white;
  padding: 5px 15px 0px 15px;
  .viewAllMembers{
    width: calc(100% + 30px);
    margin-left: -15px;
    height: 22px;
    background: rgba(234,234,234,1);
    cursor: pointer;
    border-radius: 0 0 6px 6px;
    font-family: Rubik;
    font-size: 12px;
    line-height: 16px;
    color: #0D4270;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
}
.teamDetailsV2{
  display: flex;
  width:100%;
  height: calc(100% - 70px);
  flex-direction:column;
  justify-content:center;
  align-items:center;
  background:white;
  padding: 5px 15px 0px 15px;
  border-radius: 0 0 6px 6px;
  .viewAllMembers{
    width: calc(100% + 30px);
    height: 22px;
    background: rgba(234,234,234,1);
    cursor: pointer;
    border-radius: 0 0 6px 6px;
    font-family: Rubik;
    font-size: 12px;
    line-height: 16px;
    color: #0D4270;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  
  }
}


.wrapperData{
  width: 100%;
}
.imageBackground {
  position: absolute;
  ${'' /* -webkit-position: absolute; */}
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 8px 8px 8px;
  z-index: 1;
  .view-button {
    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 24px;
    min-width: 160px;
    width: 368px;
    height: 40px;
    background: ${({background}) => background ? background : '#007AB1'};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${({cursor}) => cursor ? "not-allowed" : 'pointer'};
    border-radius: 6px;
    color: white;
    text-align: center;
    padding: 12px 20px;
    ${'' /* margin-left:20px; */}
    :hover{
    background:#005C87;
    color:#ffff;
}

  }
}

`;



const AddNewTeam = styled.div`
display: flex;
flex-direction:column;
width: 390px;
height: 420px;
padding: 20px 16px;
justify-content: center;
align-items: center;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid var(--navy_blues_60, rgba(0, 92, 135, 0.60));
`;


const TeamImage = styled.div`
  img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
  }
`;

const TeamCardV2 = styled.div`
width:100%;
display: flex;
flex-wrap:wrap;
border-radius:6px;

.headingDiv{

font-family: 'Rubik-medium';
font-weight: 500;
font-size: 16px;
line-height: 20px;

display: flex;
align-items: center;
color: #0D4270;
  .number{
    color: #69C2FF;
  }
  .max{
justify-content:flex-end;
color: #9C9C9C;
  }
}

.teamDetails{
display: flex;
width:100%;
flex-direction:column;
justify-content:center;
align-items:center;
background:white;
padding: 5px 0px 0px 0px;
}
`;


const CreateNewTeam = styled.div`
display: flex;
width: 400px;
height: 384px;
padding: 82px 16px;
justify-content: center;
align-items: center;
flex-shrink: 0;

`
;




const AddMemberButton = styled.div`
cursor:pointer;
  width:190px;
  height:50px;
display:flex;
border-radius:6px;
justify-content:center;
align-items:center;
border: 1px solid rgba(0, 47, 71, 1);
color:#0D4270;
font-family:rubik-medium;
font-size:18px;
font-weight:500;
margin:15px 0;
`;

const MemberData = styled.div`
  width:100%;
  display:flex;
  border-top:${({ last }) => last ? "none" : "1px solid rgba(156, 156, 156, 0.4)"};
  margin-bottom:5px;
  .memberProfile{
    position:relative;
    ${'' /* height: 50px; */}
    width: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius:6px;
    >img{
    ${'' /* border:${({ captain }) => captain ? "2px solid #69c2ff" : "none"}; */}
    height: 100%;
    width: 100%;
    border-radius:30px;
    }
  }
  .captain{
    margin-top:25px;
    width:24px;
    height:24px;
  }
.memberName{
  font-family: Rubik;
  ${'' /* font-weight:bold; */}
  font-size:16px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  padding-left: 10px;
  width: 100%;
  ${'' /* margin: auto 0px auto auto; */}
  >div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #005C87;
    
    .max{
      width:68px;
      height: 28px;
      padding: 8px 12px 8px 12px;
      font-family: Rubik;
      font-size: 14px;
      color: #ffff;
      background:#76AB78;
      border-radius:24px;
      ${'' /* margin-left:120px; */}
      display: flex;
      align-items: center;
      justify-content: center;
   }}
.wellnessChampion{
    width: 100%;
    margin-top:4px;
    div{
      >img{
  width: 111px;
  height: 13px;
  display: flex;
}

    }
>img{
  width: 111px;
  height: 13px;
  display: flex;
}
>span{
font-family: Rubik;
font-size: 10px;
line-height: 9px;
color: #fff;
margin-top: -11px;
display: flex;
justify-content: flex-start;
margin-left: 10px;
}}
}


.wellnessChampionNew {
      width: 100%;
      margin-top: 4px;
      
      > span {
        color: var(--nutrition, #76AB78);
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: bold;
        line-height: 20px;
        display:flex; 

        >div{
         margin-right:5px;
        }
      }
    }

`;

const MemberDataV2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 90px;
  border-top: ${({ border }) => (border ? "none" : "1px solid #9c9c9c")};
  // border-bottom:1px solid #9c9c9c;
  .imageDiv {
    width: 60px;
    height: 60px;
    margin-right: 15px;
    position: relative;
    border-radius: 6px;
    border: ${({ captain }) => (captain ? "2px solid #69c2ff" : "none")};
    background: #69c2ff;
    img {
      width: 100%;
      border-radius: 4px;
      height: 100%;
    }
  }
  .textBox {
    .wellnessChampion {
      width: 100%;
      margin-top: 4px;
      > img {
        width: 111px;
        height: 13px;
        display: flex;
      }
      > span {
        font-family: Rubik;
        font-size: 10px;
        line-height: 9px;
        color: #fff;
        margin-top: -11px;
        display: flex;
        justify-content: flex-start;
        margin-left: 10px;
      }
    }

    > h1 {
      margin: 0;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #0d4270;
      .max {
        color: #fd7175;
      }
    }
    > h2 {
      margin: 0;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #9c9c9c;
    }
  }

  .menueButton {
    margin: auto;
    margin-right: 0px;
    display: flex;
    height: 6px;
    width: 25px;
    position: relative;
    > img {
      height: 6px;
      width: 25px;
      cursor: pointer;
    }
    .btn-group {
      position: absolute;
      top: -3px;
      &:active,
      &:focus,
      &:active:focus {
        background-color: transparent;
        background: transparent;
      }

        >button{
          border:none;
background-color:transparent;
          background:transparent ;
          box-shadow:none;
          &:active,  &:focus, &:active:focus {
            background-color:transparent;
            background:transparent ;
}
      }
      >ul{
        min-width:unset;width:135px;
        left:-105px}

      }
      .dropdown {
        button{
          border:none;
          background-color:transparent;
          background:transparent ;
          box-shadow:none;
          margin-top: -10px;
          margin-left: -30px;
          &:active,  &:focus, &:active:focus {
            background-color:transparent;
            background:transparent ;
          }
        }
      }
    }

`;

const MemberDataV3 = styled.div`
display:flex;
  // justify-content:space-between;
  align-items:center;
  width:100%;
  height:90px;
  border-top:${({ border }) => border ? "none" : "1px solid #7eadc2"};
  .imageDiv{   width:60px;
    height:60px;
    margin-right:15px;
    position:relative;
    border-radius:6px;
    border:${({ captain }) => captain ? "2px solid #69c2ff" : "none"};
    background: #69c2ff;
  img{
    width:100%;
    border-radius:4px;
    height:100%;
  }
}
  .textBox{
    .anniversary-champion{
    font-family: "Rubik";
    font-size: 10px;
    color: #1E76AB;
    / margin-left: 40px; /
    width: calc(100% - 52px);
    display: block;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    background-size: 100% 100%;
    color: rgb(255, 255, 255);
    background-image: url(/images/wellness.png);
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    line-height: 9px;
    width: 132.12px;
    height: 18px;
    margin-bottom: 0px;
   

    }

    .wellnessChampion{
      width: 100%;
      margin-top:4px;
  >img{
    width: 111px;
    height: 13px;
    display: flex;
  }
  >span{
  font-family: Rubik;
  font-size: 10px;
  line-height: 9px;
  color: #fff;
  margin-top: -11px;
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
  }}
    >h1{
      margin:0;
      font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #0D4270;
.max{
  color: #69C2FF;
 }
    };
    >h2{
      margin:0;
      font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #9C9C9C;
    }
  }

.menueButton{
  margin: auto;
  margin-right: 0px;
  display:flex;
  height:6px;
  width:25px;
  position:relative;
  >img{
    height:6px;
    width:25px;
    cursor:pointer;
  }
  .btn-group{
position:absolute;
top:-3px;
&:active,  &:focus, &:active:focus {
 
  background-color:transparent;   
background:transparent ;
}

>button{
border:none;
background-color:transparent;   
background:transparent ;
box-shadow:none;
&:active,  &:focus, &:active:focus {
background-color:transparent;   
background:transparent ;
}
}
>ul{
  min-width:unset;width:135px;
  left:-105px}

  }
}
`;

const Tile = styled.div`
 display:flex;
//  margin-right:20px;
  justify-content:flex-start;
  align-items:center;
  width:417px;
  height:90px;
  border:1px solid #9c9c9c;
  padding:15px;
  div{
    >img{
    width:60px;
    height:60px;
    margin-right:15px;
  }
  }
  >img{
    width:60px;
    height:60px;
    margin-right:15px;
  }
  .textBox{
    >h1{
      margin:0;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #0D4270;
    };
    >h2{
      margin:0;      
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #9C9C9C;
    }
  }
.radioButton{
  margin: auto;
  margin-right: 0px;
  width:30px;
  height:30px;
  border-radius:50%;
  border:1px solid #9c9c9c;
  display:flex;
  justify-content:center;
  align-items:center;
>img{
  width:24px;
  height:24px;
  margin-right: 0px !important;
}
}
  `

const TileWraper = styled.div`
  display:flex;
  justify-content:flex-start;
  align-items:flex-start;
  flex-wrap:wrap;
  width:100%;
  flex-direction:row;
  padding: ${({ padding }) => padding ? '20px 17px 20px 25px' : '20px 0 20px 20px'};
  height: 400px;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 7px;
    height: 45px;
  }

  ::-webkit-scrollbar-thumb {
    background: #c6c2c2;
    cursor: pointer;
    border-radius: 2.5px;
  }

  ::-webkit-scrollbar-track {
    background: #f2f0f0;
    border-radius: 2.5px;
  }
  `

const UpdateTeamButton = styled.button`
  display:flex;
   justify-content:center;
   align-items:center;
   border:none;
   cursor:"pointer";
   background:${({ disabled }) => disabled ? "rgba(0, 122, 177, 0.30)" : "#007AB1"};
   border-radius:3px;
   width:425px;
   height:50px;
   font-family: 'Rubik-medium';
   font-weight: 500;
   font-size: 18px;
   line-height: 24px;
   / identical to box height, or 133% /
   text-align: center;
   color: #FFFFFF;

 `

const AddtoTeamButton = styled.button`
 display:flex;
  justify-content:center;
  align-items:center;
  border:none;
  cursor:"pointer";
  background:${({ disabled }) => disabled ? "rgba(0, 122, 177, 0.30)" : "#007AB1"};  
  border-radius:3px;
  width:425px;
  height:50px;
  font-family: 'Rubik-medium';
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  / identical to box height, or 133% /
  text-align: center;
  color: #FFFFFF;

`
const CustomDropdownMenue = styled(DropdownButton)`
.dropdown-menu{
  position: absolute;
    top: 100%;
    left: ${({ left }) => left && left + "!important" };
    ${'' /* left: 0 !important; */}
    z-index: 1000;
    display: none;
    float: left;
    min-width: 135px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    
}
.dropdown-toggle:empty::after{
  visibility:hidden;
}
  width: 100%;
  text-align: left;
  margin-top: 0px;
  text-transform: capitalize;
  height: 0px;
  color: #0D4270;
  font-size:16px;
  font-family: 'rubik-medium';
border:none;
background:transparent;

  >span{
    display:none;
  }

  &:active,  &:focus, &:active:focus {
    outline: none;
    border:none;
    background-color:transparent;   
background:transparent ;
    box-shadow: none;
    // color: #9c9c9c;
    color: #0D4270;
    font-size:16px;
    font-family: 'rubik-medium';
    border: 1px solid #9C9C9C;
  }
`;

const CustomDropdownMenuItem = styled(DropdownItem)`
  width: 100% !important;
  text-transform: capitalize;
  font-family:Rubik-Regular !important;
  
  > a {
    &:active, &:hover, &:focus {
      outline: none;
      color:black;
      background-color:rgba(105,194,255,0.1) !important;
      border-radius:6px !important;
      font-family:Rubik-Regular;
    }
  }
  .menuText{
    color: #007AB1;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 20px; 
    margin-bottom:10px;
    margin-top:10px;
  }
 
`;
const BottomStroke = styled.div`
 border-bottom: 1px solid rgba(0, 92, 135, 0.10);

`;

const ImageInput = styled.label`
  color: ${({ Color }) => Color ? Color : 'white'};
  padding: 9px 20px;
  background-color:${({ bgColor }) => bgColor ? bgColor : '#159fc9'};
  border-radius: 30px;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  line-height: ${({ lineHeight }) => lineHeight && '1.428571429'};
  margin-top: 10px;
  margin-top: ${({ margin }) => margin ? margin : '10px'};
  font-family: rubik-medium;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  > input {
    opacity: 0;
    z-index: -1;
    position: absolute;
    bottom: ${({ imageReload }) => imageReload && '0'};
  }
  
  ${(props) => props.showAtBottom && css`
    position: absolute;
    left: calc(40% + 20px);
    bottom: 0;

    @media (max-width: 500px) {
      left: 0;
      position: static;
    }
  `}`

const InputImage = styled(ImageInput)`
display: flex;
justify-content: center;
align-items:center;
width:164px;
height:50px;
background-color:#69C2FF;
font-size:18px;
margin:0 25px;
border-radius:3px;
cursor:pointer
  padding: 0;
  margin-bottom: 0;
  > i {
        font-family:rubik-medium;
        font-style:normal;
        font-weight:400;
        font-size:18px;
        color:#fff; 
        text-transform: capitalize;
  }
  @media (max-width: 600px) {
    position: absolute;
    background-color:#c4c4c4;
    margin:0;
    height: 40px;
    width: 125px;
    top: 85px;
    border-radius: 0 0 175px 175px;
    font-size: 10px;
    >i{
      font-size:10px;
    }
  }
`;

const CaptainLogo = styled.img`
position:absolute;
width:24px !important;
height:24px !important;
right:-8px;
top:-8px;
`

const SearchIcon = styled.div`
width: 22px !important;
height:22px !important;
margin: auto 0px auto 10px;
// position: absolute;
>img{
  width: 100%;
  height: 100%;
}
`;

const SearchInput = styled.input`
.placeholder{
  color: red;
}
placeholder{
  color: red;

}
  padding: 0px 10px 0px 10px;
    font-family: 'Rubik';
    font-weight: 'normal';
    font-size: 16px;
    line-height: 20px;
    width: 352px;
    width: 100%;
    height: 50px;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 3px;
  color: #9c9c9c;
  border: 0px;
  ::placeholder,
  ::-webkit-input-placeholder {
    font-family: 'Rubik';
    color: #9c9c9c;
  }
  :-ms-input-placeholder {
     color: red;
  }

  button, input, optgroup, select, textarea{
    color: #9c9c9c;
  }
  
  &:hover, &:active, &:focus {
    outline: none;
  }
`;

const DropdownWrapper = styled.div`
width:100%;
height:100px;
display:flex;
justify-content:flex-start;
align-items:center;
padding:25px;
border-bottom:1px solid #9c9c9c;
.text{
  font-family:rubik;
  font-size:16px;
  margin-right:15px !important;
}
.dropdown-menu {
  height: ${({scroll}) => scroll ? '400px' : ''};
  overflow-y: ${({scroll}) => scroll ? 'auto' : 'none'};
  ::-webkit-scrollbar {
    width: 2px;
  }
}
`;

const TeamCardNew = styled.div`
width:100%;
display: flex;
flex-wrap:wrap;
border-radius:6px;

padding: 0px 25px;
height: 550px;
overflow-x: hidden;
overflow-y: auto;
::-webkit-scrollbar {
  width: 7px;
  height: 45px;
}

::-webkit-scrollbar-thumb {
  background: #6097b1;
  cursor: pointer;
  border-radius: 2.5px;
}

::-webkit-scrollbar-track {
  background: #e6e6e6;
  border-radius: 2.5px;
}


.headingDiv{

font-family: 'Rubik-medium';
font-weight: 500;
font-size: 16px;
line-height: 20px;

display: flex;
align-items: center;
color: #0D4270;
  .number{
    color: #69C2FF;
  }
  .max{

color: #9C9C9C;
  }
}

.teamDetails{
display: flex;
width:100%;
flex-direction:column;
justify-content:center;
align-items:center;
background:white;
}
`;

const TeamCreationNotification = styled.div`
width:100%;
height:0px;
position: relative;
justify-content: center;
display: flex;
bottom: 20px;

.card-style{
height: 70px;
width: ${({width}) => width ? width : '290px'};
border-radius: 6px;
background: ${({background}) => background ? background : 'white'};
display:flex;
border: 1px solid #005c87;
z-index:1000;
position:relative;
box-shadow: 0px 4px 4px 0px #00000040;
.icon{
  align-items:center;
width:50px;
height:50px;
display:flex;
margin-left:10px;
margin-right:15px;
margin:auto;
background: #f6faf4;
margin: auto;
display: flex;
justify-content: center;
align-items: center;
margin-left: 10px;
border-radius: 6px;
  >img{
  width:24px;
  height:24px;
  }
}
.buttonName{
  width:calc(100% - 60px);
  display:flex;
  font-family: "Rubik-Medium";
  font-size: 16px;
  line-height: 20px;
  color:#005c87;
  margin: auto;
  padding-right: 10px;
  padding-left:15px;
}
}
.notification{
  width:calc(100% - 60px); 
  display:flex;
  flex-wrap:wrap;
  margin: auto;
  .buttonName{
    display:flex;
    font-family: "Rubik-Medium";
    font-size: 16px;
    line-height: 20px;
    color:#005c87;
    padding-right: 10px;
    padding-left:15px;
    width:100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display:block;
  }
  .success{
    display:flex;
    font-family: "Rubik";
    font-size: 14px;
    line-height: 20px;
    color:#669db7;
    padding-right: 10px;
    padding-left:15px;
    width:100%;
  }
}
`;

const ToolTip = styled.div`
display:flex;
margin: ${({margin}) => margin ? margin : '0 35px 0 0'};
width: ${({width}) => width ? width : '60px'};
height: ${({height}) => height ? height : '60px'};
cursor:pointer;
}
>div{
  width:40px;
  height:40px;
  display:flex;
  margin:auto;
  align-items: center;
  justify-content: center;
  background: ${({active, activeValue}) =>activeValue?activeValue: active ? "#0D4270" : 'rgba(156, 156, 156, 0.1)'};
  border-radius:6px;
  padding-right:${({padding}) => padding ? "5px" : ''};
  >img{
    width:24px;
    height:24px;
  }
  >svg{
    fill-opacity:  ${({activeOpacity}) =>activeOpacity?"" : ''};
    >path{
      fill: ${({ fill }) => fill};
      fill-opacity:  ${({activeOpacity}) =>activeOpacity?"" : ''};
    }
    >svg{
      fill-opacity:  ${({activeOpacity}) =>activeOpacity?"" : ''};
      >path{
        fill: ${({ fill }) => fill};
      }
      >g{
        >g{
          fill: ${({ fill }) => fill};
        }
        >path{
          fill: ${({ fill }) => fill};
        }
      }
    }
  }
}

`;

const ToolTipNew = styled.div`
display:flex;
margin: ${({margin}) => margin ? margin : '0 35px 0 0'};
width: ${({width}) => width ? width : '60px'};
height: ${({height}) => height ? height : '60px'};
cursor:pointer;
}
>div{
  width:40px;
  height:40px;
  display:flex;
  margin:auto;
  align-items: center;
  justify-content: center;
  background: ${({active, activeValue, transparent}) =>transparent?'none': activeValue?activeValue: active ? "#005c87" : 'rgba(0, 122, 177, 0.05)'};
  border-radius:6px;
  padding-right:${({padding}) => padding ? "5px" : ''};
  >img{
    width:24px;
    height:24px;
  }
  >svg{
    fill-opacity:  ${({activeOpacity}) =>activeOpacity?"0.6" : ''};
    >path{
      fill: ${({ fill }) => fill};
      fill-opacity:  ${({activeOpacity}) =>activeOpacity?"0.6" : ''};
    }
    >svg{
      fill-opacity:  ${({activeOpacity}) =>activeOpacity?"0.6" : ''};
      >path{
        fill: ${({ fill }) => fill};
      }
      >g{
        >g{
          fill: ${({ fill }) => fill};
        }
        >path{
          fill: ${({ fill }) => fill};
        }
      }
    }
  }
}



`;

export { TileWraper,SearchIcon,SearchInput, AddNewTeam, MemberDataV3,BottomStroke, TeamImage,CreateNewTeam, CaptainLogo, StyledModalCreateTeams, InputImage, StyledCSVModal, SelectImage, CustomRadioButton, TeamCardV2, CustomDropdownMenuItem, UpdateTeamButton, CustomDropdownMenue, TeamDetailsStyledModal, TitleContainerEmployee, AddtoTeamButton, FieldTitle, CustomDropdown, CustomMenuItemEmployee, DropdownArrowEmployee, Layout, AddEmployeeStyledModal, Tile, MemberData, MemberDataV2, AddMemberButton, ActivityDropdown, StyledInputNew, CustomTitle, StyledProgressBar, OnDemandButton, StyledModal, CustomMenuItem, TitleContainer, Contaniner, DropdownArrow, CardWrapper, TeamCard, Main,SeeAllStyledModal, DropdownWrapper, TeamCardNew, TeamCreationNotification, ToolTip, ToolTipNew, ClassCategoryDropdown, NewCustomDropdown};